import { render, staticRenderFns } from "./KurccSearchBox.vue?vue&type=template&id=671d0584&"
import script from "./KurccSearchBox.vue?vue&type=script&lang=js&"
export * from "./KurccSearchBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VExpandXTransition,VIcon,VRow,VTextField})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
